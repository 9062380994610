'use client';

import { usePathname } from 'next/navigation';
import SELLERS from '@/jsons/sellers';
// import { useCookies } from 'react-cookie';

export function useSeller() {
  // const [cookies] = useCookies(['oms_seller']);
  const pathname = usePathname();
  // console.log('pathname', pathname);
  let sellerId;
  if (pathname.includes('/happyfaceworks/')) {
    sellerId = 'happyfaceworks';
  } else if (pathname.includes('/rileyprint-uk/')) {
    sellerId = 'rileyprint-uk';
  } else if (pathname.includes('/rileyprint/')) {
    sellerId = 'rileyprint';
  }

  if (!sellerId) {
    return null;
    // throw new Error('Seller is not selected.');
  }
  return SELLERS.find(s=>s.id === sellerId);
}
