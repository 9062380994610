'use client';

import React, { useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { useAuth0 } from '@auth0/auth0-react';
import { LogoutButton } from '@/components/auth';

import { useSeller } from '@/components/seller';

import SELLERS from '@/jsons/sellers';

export const ALLOWED_EMAILS = ['steve@ninekilobits.com', 'viv@rileyprint.com'];

export function NavBar() {
  const { user } = useAuth0();
  return <NavBarWithUser user={user}/>;
}

export function NavBarWithUser({ user }) {
  let [showMenu, setShowMenu] = useState();
  // const [cookies] = useCookies(['oms_seller']);
  // let seller = SELLERS.find((s)=> s.id === sellerId) || SELLERS[0];
  let seller = useSeller();
  const navBarColor = process.env.NEXT_PUBLIC_TARGET_ENV === 'staging' ? 'bg-orange-400' : 'bg-gray-900';

  return <nav className={`flex ${navBarColor} text-white items-center p-2`}>
    <Head>
      <title>OMS</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      <link rel="manifest" href="/site.webmanifest"/>
    </Head>
    <div className="font-bold text-xl mx-3">OMS{seller && ' - ' + seller.id}</div>
    <div className="m-2 relative">
      <div className="nav-link cursor-pointer" onClick={()=>setShowMenu(showMenu === 'o' ? null : 'o')}>Orders</div>
      {showMenu === 'o' && <div className="absolute z-20 bg-gray-800 p-4 mt-2 -ml-4">
        {SELLERS.map(s=>{
          if (!s.hidden || ALLOWED_EMAILS.includes(user.email)) {
            return <Link
              href={`/${s.id}/orders`}
              key={s.id}
              className="block p-4"
              onClick={()=>setShowMenu(null)}
            >
              <nobr>{s.name}</nobr>
            </Link>;
          }
          return null;
        })}
      </div>}
    </div>
    <div className="m-2">
      <div className="nav-link cursor-pointer" onClick={()=>setShowMenu(showMenu === 'f' ? null : 'f')}>Fulfillments</div>
      {showMenu === 'f' && <div className="absolute z-20 bg-gray-800 p-4 mt-2 -ml-4">
        {SELLERS.map(s=>{
          if (!s.hidden || ALLOWED_EMAILS.includes(user.email)) {
            return <Link
              href={`/${s.id}/fulfillments`}
              key={s.id}
              className="block p-4"
              onClick={()=>setShowMenu(null)}
            >
              <nobr>{s.name}</nobr>
            </Link>;
          }
          return null;
        })}

      </div>}
    </div>

    <div className="m-2">
      <Link className="nav-link" href="/design">Design</Link>
    </div>
    <div className="m-2">
      <Link className="nav-link" href="/products">Products</Link>
    </div>
    <div className="m-2">
      <a className="nav-link" href="https://www.notion.so/Rileyprint-Knowledge-Base-8483264fe95f478f9e21066deaf4aae4" target="notion">Knowledge Base</a>
    </div>
    <div className="flex-grow m-2"></div>
    <UserMenu user={user}/>

  </nav>;
}
export function PMSNavBar() {
  return <nav className="flex bg-black text-white items-center p-2">
    <Link to={'/products/listings'} className="mx-4">Listings</Link>
    <Link to={'/design-templates'} className="mx-4">Templates</Link>
    <Link to={'/products'} className="mx-4">Product Specs</Link>
    <Link to={'/manage/followup-emails'}>Follow-up Emails</Link>
  </nav>;
}

function UserMenu({ user }) {
  const [openMenu, setOpenMenu] = useState(false);
  return <div className="relative">
    <div className="m-2 cursor-pointer" onClick={()=>setOpenMenu(!openMenu)}><strong>{user.name}</strong></div>
     {openMenu && <div
       className="absolute z-10 bg-gray-800 w-40 right-0"
       onClick={()=>setOpenMenu(!openMenu)}>
       <LogoutButton className="m-4 my-6 cursor-pointer"/>
    </div>}
  </div>;
}
